<template>
	<div id="form-hotmess" class="container has-text-centered">
		<h1 class="title is-3">
			Hotmess to Little Black Dress Signup
		</h1>
		<form class="checkout has-text-left" autocomplete="off" @submit.prevent="checkout">
			<b-field label="Full Name">
				<b-input v-model="name" :disabled="loading"></b-input>
			</b-field>
			<b-field label="Email Address">
				<b-input v-model="email" type="email" :disabled="loading"></b-input>
			</b-field>
			<b-field label="Phone Number">
				<b-input v-model="phone" :disabled="loading"></b-input>
			</b-field>
			<br />
			<b-field>
				<b-button
					class="button is-primary is-fullwidth has-text-weight-bold"
					@click="submit()"
					:loading="loading"
				>
					Checkout
				</b-button>
			</b-field>
		</form>
	</div>
</template>

<script>
export default {
	data: function() {
		return {
			name: this.$route.query.n || '',
			email: this.$route.query.e || '',
			phone: this.$route.query.p || ''
		};
	},
	props: ['loading'],
	methods: {
		submit() {
			this.$parent.pay(
				'hotmess',
				[
					{
						name: this.name,
						email: this.email,
						phone: this.phone
					}
				],
				{
                    email: this.email
                },
				this.loading
			);
		}
	}
};
</script>
